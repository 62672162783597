import { InputComponent } from "@/components";
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  components: {
    InputComponent
  },
  data: () => ({
    email: ""
  }),
  methods: {
    ...mapActions("account", {
      forgotPassword: "FORGOT_PASSWORD"
    }),
    requestNewPassword() {
      if (this.email) {
        this.forgotPassword({ email: this.email });
      }
    }
  }
};
